<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model
      ref="form"
      :model="dataForm"
      :rules="rules"
    >
      <a-form-model-item label="升级包名称" prop="packageName">
        <a-input v-model="dataForm.packageName" placeholder="请输入"></a-input>
      </a-form-model-item>
      <a-form-model-item label="设备类型" prop="deviceTypeId">
        <a-select v-model="dataForm.deviceTypeId" placeholder="请选择">
          <a-select-option v-for="(item,key) in devList" :key="key" :value="item.id">{{ item.name }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="文件" prop="packagePath">
        <a-upload
          action="https://pc.ruixuniot.com/admin-api/common/local/file/upload"
          :headers="header"
          class="upload-demo"
          ref="upload"
          @change="handleChange"
          accept=".bin"
          :showUploadList="false"
        >
          <a-button :loading="uploadLoading"> <a-icon type="upload" /> 选取文件 </a-button>
          <!-- <span v-if="fileName">{{ fileName }}</span>
          <span v-show="false">{{ dataForm.packagePath }}</span> -->
          <p>{{ dataForm.packagePath }}</p>
        </a-upload>
      </a-form-model-item>
      <a-form-model-item label="升级包版本号" prop="packageVersion">
        <a-input
          v-model="dataForm.packageVersion"
          placeholder="请输入"
        ></a-input>
      </a-form-model-item>
      <a-form-model-item label="附加信息" prop="additional">
        <a-textarea
          v-model="dataForm.additional"
          placeholder="请输入"
          :auto-size="{ minRows: 2, maxRows: 5 }"
        />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import store from '@/store'
import { getappVersion, addappVersion, editappVersion } from '@/api/system/version'
import { deviceTypeList } from '@/api/pages/device'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      header: { Authorization: 'Bearer ' + store.getters.access_token },
      submitLoading: false,
      formTitle: '',
      fileName: '',
      uploadLoading: false,
      devList: [],
      // 表单参数
      dataForm: {
        id: 0,
        deviceTypeId: undefined,
        packageName: '',
        packagePath: '',
        packageVersion: '',
        additional: ''
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        deviceTypeId: [
          { required: true, message: '请选择设备类型', trigger: ['blur', 'change'] }
        ],
        packagePath: [
          { required: true, message: '请上传升级包', trigger: ['blur', 'change'] }
        ],
        packageName: [
          { required: true, message: '升级包名称', trigger: 'blur' }
        ],
        packageVersion: [
          { required: true, message: '升级包版本', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    handleUpdate (row, ids) {
      this.reset()
      const configId = row ? row.id : ids
      this.formType = 2
      this.open = true
      this.formTitle = '修改'
      getappVersion({ id: configId }).then(response => {
        this.dataForm = response.data
      })
    },
    onClose () {
      this.open = false
    },
    // 上传成功
    handleChange (info) {
      console.log(info.file.status, info.file)
      if (info.file.status !== 'uploading') {
        this.uploadLoading = true
      }
      if (info.file.status === 'done') {
        this.fileName = info.file.response.data
        this.dataForm.packagePath = info.file.response.data
        this.$message.success(`上传成功`)
        this.uploadLoading = false
      } else if (info.file.status === 'error') {
        this.$message.error(`上传失败`)
        this.uploadLoading = false
      }
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.fileName = ''
      this.dataForm = {
        id: 0,
        deviceTypeId: undefined,
        packageName: '',
        packagePath: '',
        packageVersion: '',
        additional: ''
      }
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
      deviceTypeList().then(res => {
        this.devList = res.data
        // this.fileName = res.data.packagePath
        this.dataForm.packagePath = res.data.packagePath
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.dataForm.id && this.dataForm.id !== 0) {
            editappVersion(this.dataForm).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addappVersion(this.dataForm).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
